// Overrides Bootstrap Colors
$indigo: #025e73;
$red: #c00404;
$yellow: #ffc72c;
$green: #00b398;
$cyan: #00a7dc;
$gray-600: #98a4ae;
$purple: rgb(89, 81, 139);

// Additional Brown University Theme Colors
$brown: #4e3629;
$beige: #efece5;
$sand: #ccc6b8;
$light-brown: #a79a94;
$medium-brown: #745d54;
$dark-brown: #4e3629;
$brown-100: #a79a94;
$brown-200: #745d54;

$theme-colors: (
  "primary": $purple,
  "info": $indigo,
);
