// Override Bootstrap Variables
@import "variables";

// Import Bootstrap
@import "~bootstrap/scss/bootstrap.scss";

@import "_footer";

.fullWidth {
  width: 100%;
}

.volumeViewer {
  height: 90vh !important;
}

#opacityControls.pointer {
  cursor: pointer;
}

.opacity-controls-text-size {
  font-size: 11px;
}

.fullWidth {
  width: 100%;
}
.controls {
  width: min-content;
}
